<script>
export default {
  name: "App",
  data() {
    return {
      drawer: null,
      items: [
        { title: "Home", path: "/", icon: "" },
        { title: "Community Service", path: "/charity", icon: "" },
        { title: "Company News", path: "/blog", icon: "" },
        { title: "Contact", path: "/contact", icon: "" },
      ],
    };
  },
};
</script>
<template>
  <v-app>
    <v-app-bar app color="dark" dark class="flex-grow-0">
      <div>
        <router-link to="/">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="@/assets/MTSLogoWhite.png"
            transition="scale-transition"
            width="80"
          />
        </router-link>
      </div>
      <v-col style="position: relative">
        <button absolute right @click.stop="drawer = !drawer">Menu
          <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
        </button>
      </v-col>
      <v-container class="fill-height">
        <v-navigation-drawer
          height="300"
          class="overflow-hidden justify-right"
          v-model="drawer"
          absolute
          temporary
          transition="fade-transition"
        >
          <v-list dense>
            <v-list-item
              v-for="item in items"
              :key="item.title"
              :to="item.path"
              link
            >
              <router-link :to="item.path">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
              </router-link>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-container>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
