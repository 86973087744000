
<script>
/* eslint-disable */
import FooterComp from "../components/footerComp.vue";
const message =
  "Life is beautiful if you stay the course and keep your focus on your dream. That is what I like to tell my daughters. I have come a long way from growing up in the 'Barrio' neighborhoods of Santa Ana to become the business owner I am today in an industry that I have come to love and respect. Trucking. Seventeen years ago I began working in the trucking industry with no prior knowledge or skill on how to register commercial vehicles to travel the 48 states. Trust me, I was not a fast learner. There were times I wanted to quit and find another industry to work for, but I came to love the trucking industry. The truckers, sales representatives who sold the commercial vehicles, and the DMV in Sacremento who really wanted to help. I learned that the trucking industry is a very united family that I wanted to be part of. With the help and guidance of my mentor, I learned the ins and outs of the trucking industry and how to register the vehicles under the IRP program also know as Internation Registration Plan. That is where Mayra's Trucking Services, LLC. was established. I created this company to focus on quality of service. I am a very direct person, but with the understanding that I will always be honest and helpful. That I will always treat my customers' business with the respect and attention it deserves. My dream was to own my own business and now that dream is realized.";
const quotes = {
  motto: "Professional, Reliable and Honest",
  experience:
    "With more than 18 hours of experience, we will do everything in our power to help you be successful",
  possibilities: "The possibilities are endless",
};
export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      txt: "",
      services: [
        {
          item: "IRP Registration",
          color: "red darken-1",
          textColor: "black--text",
        },
        {
          item: "EIN / Tax ID Number",
          color: "white darken-4",
          textColor: "black--text",
        },
        {
          item: "2290 Filing",
          color: "white darken-2",
          textColor: "black--text",
        },
        {
          item: "UCR Filing",
          color: "grey darken-4",
          textColor: "white--text",
        },
        {
          item: "Drug & Alcohol Consortium",
          color: "grey darken-4",
          textColor: "white--text",
        },
        {
          item: "MC Authority",
          color: "red darken-1",
          textColor: "black--text",
        },
      ],
      byAppointment: [
        { item: "USDOT", color: "red darken-1", textColor: "black--text" },
        { item: "EPN", color: "white darken-4", textColor: "black--text" },
        { item: "CA#", color: "white darken-2", textColor: "black--text" },
        { item: "MCP", color: "grey darken-4", textColor: "white--text" },
        { item: "IFTA", color: "grey darken-4", textColor: "white--text" },
        { item: "UCR", color: "red darken-1", textColor: "black--text" },
      ],
    };
  },
  components: {
    FooterComp,
  },
  watch: {
    windowHeight(newWidth, oldWidth) {
      this.txt = `it changed to ${newWidth} from ${oldWidth}`;
    },
  },
  computed: {
    message() {
      return message;
    },
    quotes() {
      return quotes;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style>
</style>
<template>
  <div>
    <!-- banner -->
    <v-card max-height="700px" class="mx-auto">
      <v-img
        src="../assets/mainBG.jpg"
        width="100%"
        height="400"
        transition="fade-transition"
        class="grey darken-4"
      >
        <v-card-text color="grey darken-4" height="100px">
          <h1 class="white--text">Welcome to Mayra's Trucking Services, LLC</h1>
          <br />
          <h2 class="white--text">
            {{ quotes.motto }}
          </h2>
        </v-card-text>
      </v-img>
    </v-card>

    <v-container fluid class="mx-auto pa-6">
      <!-- services -->
      <div v-if="windowWidth > 600">
        <v-card class="mx-auto pa-6" height="300" color="grey lighten-3">
          <v-row class="px-3">
            <v-card
              class="mx-auto py-1 px-4"
              rounded="pill"
              color="blue-grey darken-5"
              id="inspire"
            >
              <h1 class="white--text">Services</h1>
            </v-card>
          </v-row>
          <v-row xs class="mx-auto">
            <v-col xs class="mx-auto">
              <v-card
                id="inspire"
                class="mx-auto"
                height="50"
                width="80%"
                shaped
                color="red darken-1"
              >
                <v-card-text class="mx-auto text-center"
                  ><h3>IRP Registration</h3></v-card-text
                >
              </v-card>
            </v-col>
            <v-col xs class="mx-auto">
              <v-card
                id="inspire"
                class="mx-auto"
                height="50"
                width="80%"
                shaped
                color="white darken-4"
              >
                <v-card-text class="mx-auto text-center"
                  ><h3>EIN / Tax ID Number</h3></v-card-text
                >
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mx-auto">
            <v-col class="mx-auto">
              <v-card
                id="inspire"
                class="mx-auto"
                height="50"
                width="80%"
                shaped
                color="white darken-2"
              >
                <v-card-text class="mx-auto text-center"
                  ><h3>2290 Filing</h3></v-card-text
                >
              </v-card>
            </v-col>
            <v-col class="mx-auto">
              <v-card
                id="inspire"
                class="mx-auto"
                height="50"
                width="80%"
                shaped
                color="grey darken-4"
              >
                <v-card-text class="mx-auto text-center white--text"
                  ><h3>UCR Filing</h3></v-card-text
                >
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mx-auto">
            <v-col class="mx-auto">
              <v-card
                id="inspire"
                class="mx-auto"
                height="50"
                width="80%"
                shaped
                color="grey darken-4"
              >
                <v-card-text class="mx-auto text-center white--text"
                  ><h3>Drug & Alcohol Consortium</h3></v-card-text
                >
              </v-card>
            </v-col>
            <v-col>
              <v-card
                id="inspire"
                class="mx-auto"
                height="50"
                width="80%"
                shaped
                color="red darken-1"
              >
                <v-card-text class="mx-auto text-center"
                  ><h3>MC Authority</h3></v-card-text
                >
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </div>

      <!-- By Appointment -->
      <div v-if="windowWidth > 600">
        <v-card class="mx-auto pa-6" height="300" color="grey lighten-3">
          <v-row class="px-3">
            <v-card
              class="mx-auto py-1 px-4"
              rounded="pill"
              color="blue-grey darken-5"
              id="inspire"
            >
              <h1 class="white--text">By Appointment Only</h1>
            </v-card>
          </v-row>
          <v-row xs class="mx-auto">
            <v-col xs class="mx-auto">
              <v-card
                id="inspire"
                class="mx-auto"
                height="50"
                width="80%"
                shaped
                color="red darken-1"
              >
                <v-card-text class="mx-auto text-center"
                  ><h3>USDOT</h3></v-card-text
                >
              </v-card>
            </v-col>
            <v-col xs class="mx-auto">
              <v-card
                id="inspire"
                class="mx-auto"
                height="50"
                width="80%"
                shaped
                color="white darken-4"
              >
                <v-card-text class="mx-auto text-center"
                  ><h3>EPN</h3></v-card-text
                >
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mx-auto">
            <v-col class="mx-auto">
              <v-card
                id="inspire"
                class="mx-auto"
                height="50"
                width="80%"
                shaped
                color="white darken-2"
              >
                <v-card-text class="mx-auto text-center"
                  ><h3>CA Number</h3></v-card-text
                >
              </v-card>
            </v-col>
            <v-col class="mx-auto">
              <v-card
                id="inspire"
                class="mx-auto"
                height="50"
                width="80%"
                shaped
                color="grey darken-4"
              >
                <v-card-text class="mx-auto text-center white--text"
                  ><h3>MCP</h3></v-card-text
                >
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mx-auto">
            <v-col class="mx-auto">
              <v-card
                id="inspire"
                class="mx-auto"
                height="50"
                width="80%"
                shaped
                color="grey darken-4"
              >
                <v-card-text class="mx-auto text-center white--text"
                  ><h3>IFTA</h3></v-card-text
                >
              </v-card>
            </v-col>
            <v-col>
              <v-card
                id="inspire"
                class="mx-auto"
                height="50"
                width="80%"
                shaped
                color="red darken-1"
              >
                <v-card-text class="mx-auto text-center"
                  ><h3>UCR</h3></v-card-text
                >
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </div>

      <!-- services mobile -->
      <div v-if="windowWidth <= 600">
        <v-card class="mx-auto pa-6" height="550" color="grey lighten-3">
          <v-row class="px-3">
            <v-card
              class="mx-auto py-1 px-4"
              rounded="pill"
              color="blue-grey darken-5"
              id="inspire"
            >
              <h1 class="white--text">Services</h1>
            </v-card>
          </v-row>
          <v-row v-for="(service, index) in services" :key="index">
            <v-col>
              <v-card
                id="inspire"
                class="mx-auto"
                height="50"
                width="80%"
                shaped
                :color="service.color"
              >
                <v-card-text class="mx-auto text-center">
                  <h3 :class="service.textColor">{{ service.item }}</h3>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </div>

      <!-- By Appointment -->

      <div v-if="windowWidth <= 600">
        <v-card class="mx-auto pa-6" height="550" color="grey lighten-3">
          <v-row class="px-3">
            <v-card
              class="mx-auto py-1 px-4"
              rounded="pill"
              color="blue-grey darken-5"
              id="inspire"
            >
              <h1 class="white--text">By Appointment Only</h1>
            </v-card>
          </v-row>
          <v-row v-for="(byApp, index) in byAppointment" :key="index">
            <v-col>
              <v-card
                id="inspire"
                class="mx-auto"
                height="50"
                width="80%"
                shaped
                :color="byApp.color"
              >
                <v-card-text class="mx-auto text-center">
                  <h3 :class="byApp.textColor">{{ byApp.item }}</h3>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </div>
    
    </v-container>
    <footer-comp></footer-comp>
  </div>
</template>