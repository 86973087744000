
<script>
/* eslint-disable */
export default {
  data() {
    return {};
  },
};
</script>

<style>
</style>
<template>
  <div>
    <v-footer dark padless>
      <v-card class="mx-auto" width="100%">
        <v-card-text class="white--text pt-0 text-center">
          Professional, Reliabel and Honest <br />
          License No. 08058 <br />
          <a href="tel:9092587731">Phone: (909)258-7731 </a>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>