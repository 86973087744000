
<script>
/* eslint-disable */
import FooterComp from "../components/footerComp.vue";
export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      latestDonationMessage: "In April 30, 2023, MTS was able to help purchase 25 bicycles for students in Durando, Mexico so that they do not have to walk the 40 minutes it takes to get to school in the morning.",
      shoeDonationMessage: "When I was in the 4th grade, my teacher donated a pair of sneakers to me because I used to go to school with holes in my shoes. My family was below middle class.  For the first time I felt seen! I never forgot the feeling of gratefulness to know that someone cared.  That is why MTS is committed to helping children like me know that they are not invincible. ",
      foodDonationMessage: "MTS was able to help 47 families with groceries during the holidays in December. The goal was to help families use their resources for other necessities in their households. In these difficult times, any help, big or small, is appreciated. ",
      benitoJuarezMessage: "Children from Benito Juarez en San Jose de la Boca received 'aguinaldos' Christmas 2023 thanks in part to MTS"
    };
  },
  components: {
    FooterComp,
  },
  watch: {
    windowHeight(newWidth, oldWidth) {
      this.txt = `it changed to ${newWidth} from ${oldWidth}`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style>
</style>

<template>
  <div>

    <v-container v-if="windowWidth > 600">
      <p max-width="600px">{{ benitoJuarezMessage }}</p>
      <v-row max-width="600px" color="blue-grey lighten-5">
        <v-col col="0" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/BenitoJuarez.jpg" lazy-src="@/assets/BenitoJuarez.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="windowWidth > 600">
      <p max-width="600px">{{ latestDonationMessage }}</p>
      <v-row max-width="600px" color="blue-grey lighten-5">
        <v-col col="0" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/with_banner.jpg" lazy-src="@/assets/with_banner.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="1" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/IMG_2.jpg" lazy-src="@/assets/IMG_2.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="2" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/IMG_3.jpg" lazy-src="@/assets/IMG_3.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="3" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/IMG_4.jpg" lazy-src="@/assets/IMG_4.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
      </v-row>
      <v-row max-width="600px" color="blue-grey lighten-5">
        <v-col col="0" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/IMG_5.jpg" lazy-src="@/assets/IMG_5.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="1" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/IMG_6.jpg" lazy-src="@/assets/IMG_6.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="2" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/IMG_7.jpg" lazy-src="@/assets/IMG_7.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="3" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/IMG_8.jpg" lazy-src="@/assets/IMG_8.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
      </v-row>
    </v-container>
    

    <v-container v-if="windowWidth > 600">
      <p max-width="600px">{{ foodDonationMessage }}</p>
      <v-row max-width="600px" color="blue-grey lighten-5">
        <v-col col="0" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/service1.jpg" lazy-src="@/assets/service1.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="1" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/service2.jpg" lazy-src="@/assets/service2.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="2" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/service3.jpg" lazy-src="@/assets/service3.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="3" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/service4.jpg" lazy-src="@/assets/service4.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
      </v-row>
      <v-row max-width="600px" color="blue-grey lighten-5">
        <v-col col="0" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/service5.jpg" lazy-src="@/assets/service5.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="1" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/service6.jpg" lazy-src="@/assets/service6.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="2" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/service7.jpg" lazy-src="@/assets/service7.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="3" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/service8.jpg" lazy-src="@/assets/service8.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
      </v-row>
      <v-row max-width="600px" color="blue-grey lighten-5">
        <v-col col="0" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/service9.jpg" lazy-src="@/assets/service9.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="1" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/service10.jpg" lazy-src="@/assets/service10.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="2" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/service11.jpg" lazy-src="@/assets/service11.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="windowWidth > 600">
      <p max-width="600px"> {{ shoeDonationMessage }} </p>
      <v-row max-width="600px" color="blue-grey lighten-5">
        <v-col col="0" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/shoe1.jpg" lazy-src="@/assets/shoe1.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="1" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/shoe2.jpg" lazy-src="@/assets/shoe2.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="2" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/shoe3.jpg" lazy-src="@/assets/shoe3.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="3" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/shoe4.jpg" lazy-src="@/assets/shoe4.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
      </v-row>
      <v-row max-width="600px" color="blue-grey lighten-5">
        <v-col col="0" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/shoe5.jpg" lazy-src="@/assets/shoe5.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="1" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/shoe6.jpg" lazy-src="@/assets/shoe6.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="2" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/shoe7.jpg" lazy-src="@/assets/shoe7.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="3" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/shoe8.jpg" lazy-src="@/assets/shoe8.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
      </v-row>
      <v-row max-width="600px" color="blue-grey lighten-5">
        <v-col col="0" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/shoe9.jpg" lazy-src="@/assets/shoe9.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="1" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/shoe10.jpg" lazy-src="@/assets/shoe10.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
        <v-col col="2" class="d-flex child-flex" cols="3">
          <v-img src="@/assets/shoe11.jpg" lazy-src="@/assets/shoe11.jpg" aspect-ratio="1" class="grey lighten-2" max-width="200px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-col>
      </v-row>
    </v-container>

    <!-- mobile -->

    <v-container v-if="windowWidth <= 600">
      <p max-width="600px">{{ benitoJuarezMessage }}</p>
      <v-row class="ma-4" max-width="600px" color="blue-grey lighten-5">
        <v-row class="d-flex child-flex ma-1" width="10%">
          <v-img src="@/assets/BenitoJuarez.jpg" lazy-src="@/assets/BenitoJuarez.jpg" aspect-ratio="1.5" class="grey lighten-2" width="90%" max-width="350px" ><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
      </v-row>
    </v-container>

    <v-container v-if="windowWidth <= 600">
      <p max-width="600px">{{ latestDonationMessage }}</p>
      <v-row class="ma-4" max-width="600px" color="blue-grey lighten-5">
        <v-row class="d-flex child-flex ma-1" width="10%">
          <v-img src="@/assets/with_banner.jpg" lazy-src="@/assets/with_banner.jpg" aspect-ratio="1.5" class="grey lighten-2" width="90%" max-width="350px" ><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/IMG_2.jpg" lazy-src="@/assets/IMG_2.jpg" aspect-ratio="1.5" class="grey lighten-2" width="90%" max-width="350px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/IMG_3.jpg" lazy-src="@/assets/IMG_3.jpg" aspect-ratio="0.9" class="grey lighten-2" width="100%" max-width="350px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/IMG_4.jpg" lazy-src="@/assets/IMG_4.jpg" aspect-ratio="1" class="grey lighten-2" width="100%" max-width="350px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
      </v-row>
      <v-row class="ma-4" max-width="600px" color="blue-grey lighten-5">
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/IMG_5.jpg" lazy-src="@/assets/IMG_5.jpg" aspect-ratio="1" class="grey lighten-2" width="100%" max-width="350px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/IMG_6.jpg" lazy-src="@/assets/IMG_6.jpg" aspect-ratio="1" class="grey lighten-2" width="100%" max-width="350px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/IMG_7.jpg" lazy-src="@/assets/IMG_7.jpg" aspect-ratio="1" class="grey lighten-2" width="100%" max-width="350px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/IMG_8.jpg" lazy-src="@/assets/IMG_8.jpg" aspect-ratio="1.2" class="grey lighten-2" width="100%" max-width="350px"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
      </v-row>
    </v-container>

    <v-container v-if="windowWidth <= 600">
      <p max-width="600px">{{ foodDonationMessage }}</p>
      <v-row class="ma-4" max-width="600px" color="blue-grey lighten-5">
        <v-row class="d-flex child-flex ma-1" width="70%">
          <v-img src="@/assets/service1.jpg" lazy-src="@/assets/service1.jpg" aspect-ratio="1" class="grey lighten-2" width="90%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/service2.jpg" lazy-src="@/assets/service2.jpg" aspect-ratio="1" class="grey lighten-2" width="90%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/service3.jpg" lazy-src="@/assets/service3.jpg" aspect-ratio="1" class="grey lighten-2" max-width="100%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/service4.jpg" lazy-src="@/assets/service4.jpg" aspect-ratio="1" class="grey lighten-2" max-width="100%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
      </v-row>
      <v-row class="ma-4" max-width="600px" color="blue-grey lighten-5">
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/service5.jpg" lazy-src="@/assets/service5.jpg" aspect-ratio="1" class="grey lighten-2" max-width="100%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/service6.jpg" lazy-src="@/assets/service6.jpg" aspect-ratio="1" class="grey lighten-2" max-width="100%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/service7.jpg" lazy-src="@/assets/service7.jpg" aspect-ratio="1" class="grey lighten-2" max-width="100%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/service8.jpg" lazy-src="@/assets/service8.jpg" aspect-ratio="1" class="grey lighten-2" max-width="100%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
      </v-row>
      <v-row class="ma-4" max-width="600px" color="blue-grey lighten-5">
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/service9.jpg" lazy-src="@/assets/service9.jpg" aspect-ratio="1" class="grey lighten-2" max-width="100%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/service10.jpg" lazy-src="@/assets/service10.jpg" aspect-ratio="1" class="grey lighten-2" max-width="100%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/service11.jpg" lazy-src="@/assets/service11.jpg" aspect-ratio="1" class="grey lighten-2" max-width="100%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
      </v-row>
    </v-container>

    <v-container v-if="windowWidth <= 600">
      <p max-width="600px"> {{ shoeDonationMessage }} </p>
      <v-row class="ma-4" max-width="600px" color="blue-grey lighten-5">
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/shoe1.jpg" lazy-src="@/assets/shoe1.jpg" aspect-ratio="1" class="grey lighten-2" max-width="100%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/shoe2.jpg" lazy-src="@/assets/shoe2.jpg" aspect-ratio="1" class="grey lighten-2" max-width="77%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/shoe3.jpg" lazy-src="@/assets/shoe3.jpg" aspect-ratio="1" class="grey lighten-2" max-width="78%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/shoe4.jpg" lazy-src="@/assets/shoe4.jpg" aspect-ratio="1" class="grey lighten-2" max-width="86%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
      </v-row>
      <v-row class="ma-4" max-width="600px" color="blue-grey lighten-5">
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/shoe5.jpg" lazy-src="@/assets/shoe5.jpg" aspect-ratio="1" class="grey lighten-2" max-width="91%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/shoe6.jpg" lazy-src="@/assets/shoe6.jpg" aspect-ratio="1" class="grey lighten-2" max-width="78%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/shoe7.jpg" lazy-src="@/assets/shoe7.jpg" aspect-ratio="1" class="grey lighten-2" max-width="63%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/shoe8.jpg" lazy-src="@/assets/shoe8.jpg" aspect-ratio="1" class="grey lighten-2" max-width="95%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
      </v-row>
      <v-row class="ma-4" max-width="600px" color="blue-grey lighten-5">
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/shoe9.jpg" lazy-src="@/assets/shoe9.jpg" aspect-ratio="1" class="grey lighten-2" max-width="50%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/shoe10.jpg" lazy-src="@/assets/shoe10.jpg" aspect-ratio="1" class="grey lighten-2" max-width="99%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
        <v-row class="d-flex child-flex ma-1" width="90%">
          <v-img src="@/assets/shoe11.jpg" lazy-src="@/assets/shoe11.jpg" aspect-ratio="1" class="grey lighten-2" max-width="50%"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular></v-row></template></v-img>
        </v-row>
      </v-row>
    </v-container>

    <footer-comp></footer-comp>
  </div>
</template>
