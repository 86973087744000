
<script>
/* eslint-disable */
import FooterComp from "../components/footerComp.vue";
export default {
  components: {
    FooterComp,
  },
  data() {
    return {
      windowHeight: window.innerHeight,
      txt: "",
    };
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
    },
  },
};
</script>

<style>
.backdrop {
  background-color: rgb(202, 228, 245);
}
</style>
<template>
  <div class="backdrop">
    <v-container>
      <v-row>
        <v-card
          class="mx-auto"
          :height="windowHeight"
          min-height="600"
          color="grey lighten-3"
          flat
        >
          <v-card-title> Where to find us </v-card-title>
          <v-img height="500" width="400" src="@/assets/HeadShot.jpg"></v-img>
          <v-card-text>
            415 W Redlands Blvd <br />
            Redlands Ca, 92373 <br />
            Monday - Friday 09:00 am to 5:00 pm <br />
            <a href="tel:9092587731">Phone: (909)258-7731 </a> <br />
            <a href="mailto:mayra@mayrastruckingservices.com"
              >mayra@mayrastruckingservices.com</a
            ><br /><br />
            <p>Check us out on  <a href="https://www.yelp.com/biz/mayras-trucking-services-redlands">Yelp</a> </p>
            <br />
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
    <footer-comp></footer-comp>
  </div>
</template>