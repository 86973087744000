import Vue from "vue";
import VueRouter from "vue-router";

import Home from '../components/LandingPage.vue';
import About from '../components/AboutTab.vue';
import Charity from '../components/CharityTab.vue';
import Blog from '../components/CompanyNews.vue';
import Contact from '../components/Contact.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/charity',
    name: 'Charity',
    component: Charity
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

// const routerOptions = [
//   { path: "/", component: "LandingPage" },
//   { path: "/contact", component: "Contact" },
// ];

// const routes = routerOptions.map(route => {
//   return {
//     ...route,
//     component: () => import(`../components/${route.component}.vue`)
//   };
// });

// Vue.use(Router);

// export default new Router({
//   mode: "history",
//   routes
// });