
<script>
/* eslint-disable */
import FooterComp from "../components/footerComp.vue";

const message = "Life is beautiful if you stay the course and keep your focus on your dream. That is what I like to tell my daughters. I have come a long way from growing up in the 'Barrio' neighborhoods of Santa Ana to become the business owner I am today in an industry that I have come to love and respect. Trucking. Seventeen years ago I began working in the trucking industry with no prior knowledge or skill on how to register commercial vehicles to travel the 48 states. Trust me, I was not a fast learner. There were times I wanted to quit and find another industry to work for, but I came to love the trucking industry. The truckers, sales representatives who sold the commercial vehicles, and the DMV in Sacremento who really wanted to help. I learned that the trucking industry is a very united family that I wanted to be part of. With the help and guidance of my mentor, I learned the ins and outs of the trucking industry and how to register the vehicles under the IRP program also know as Internation Registration Plan. That is where Mayra's Trucking Services, LLC. was established. I created this company to focus on quality of service. I am a very direct person, but with the understanding that I will always be honest and helpful. That I will always treat my customers' business with the respect and attention it deserves. My dream was to own my own business and now that dream is realized.";
const quotes = {
  motto: "Professional, Reliable and Honest",
  experience: "With more than 18 years of experience, we will do everything in our power to help you be successful",
  possibilities: "We do everything in our power to keep you on the road",
};

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      txt: "",
    };
  },
  watch: {
    windowHeight(newWidth, oldWidth) {
      this.txt = `it changed to ${newWidth} from ${oldWidth}`;
    },
  },
  components: {
    FooterComp,
  },
  computed: {
    message() {
      return message;
    },
    quotes() {
      return quotes;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style>
</style>

<template>
  <div>
    <v-card class="mx-auto ma-5" max-width="900">
      <!-- story -->
      <v-img
        height="500"
        transition="fade-transition"
        src="@/assets/storyimage.jpg"
      ></v-img>
      <v-card-text class="grey darken-4 white--text">
        <h2>
          {{ message }}
        </h2>
      </v-card-text>
    </v-card>
    <!-- quotes -->
    <div v-if="windowWidth > 600">
      <v-row class="pa-6">
        <v-card class="mx-auto py-3" flat max-width="900">
          <v-img
            height="300"
            transition="fade-transition"
            src="@/assets/trucking.jpg"
          ></v-img>
          <v-card-text class="text-center"
            ><h3>{{ quotes.experience }}</h3></v-card-text
          >
        </v-card>
        <v-card class="mx-auto py-3" flat max-width="900">
          <v-img
            height="300"
            transition="fade-transition"
            src="@/assets/freightBoxes.jpg"
          ></v-img>
          <v-card-text class="text-center"
            ><h3>{{ quotes.possibilities }}</h3></v-card-text
          >
        </v-card>
      </v-row>
    </div>
    <div v-if="windowWidth <= 600">
      <v-row class="pa-6">
        <v-card class="mx-auto py-3" flat max-width="350">
          <v-img
            height="300"
            transition="fade-transition"
            src="@/assets/trucking.jpg"
          ></v-img>
          <v-card-text class="text-center"
            ><h3>{{ quotes.experience }}</h3></v-card-text
          >
        </v-card>
        <v-card class="mx-auto py-3" flat max-width="350">
          <v-img
            height="300"
            transition="fade-transition"
            src="@/assets/freightBoxes.jpg"
          ></v-img>
          <v-card-text class="text-center"
            ><h3>{{ quotes.possibilities }}</h3></v-card-text
          >
        </v-card>
      </v-row>
    </div>
    <footer-comp></footer-comp>
  </div>
</template>